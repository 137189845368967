
.bag-tab {
  ::v-deep .el-table {
    color: #fff;
  }
}
.bag-tab {
  ::v-deep .el-table::before {
    height: 0px;
  }
}
.bag-tab {
  ::v-deep .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
  }
}

.bag-tab {
  ::v-deep .el-table tr {
    background-color: transparent !important;
  }
}
.bag-tab {
  ::v-deep .el-table tbody tr:hover > td {
    background-color: #070932 !important;
  }
}
.bag-tab{
  ::v-deep .el-table td {
  border-bottom: 1px solid #262963 !important;
}
}
.bag-tab {
  ::v-deep .el-table--enable-row-transition .el-table__body td,
  .el-table .cell {
    background-color: transparent;
  }
}

.listDate {
  ::v-deep .el-table {
    color: #fff;
  }
}
.listDate {
  ::v-deep .el-table th.el-table__cell {
    background-color: #262963 !important;
  }
}
.listDate {
  ::v-deep .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px solid #ebeef5;
  }
}
.listDate {
  ::v-deep .el-table-column--selection .cell {
    padding-left: 10px;
    padding-right: 14px;
  }
}
.listDate {
  ::v-deep .el-table::before {
    height: 0px;
  }
}
.listDate {
  ::v-deep .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
  }
}

.listDate{
  ::v-deep .el-table tr {
  background-color: transparent !important;
}
}
.listDate{
  ::v-deep .el-table tbody tr:hover > td {
  background-color: #262963 !important;
}
}
.listDate{
  ::v-deep .el-table td {
  border-bottom: 1px solid #262963 !important;
}
}
.listDate{
  ::v-deep .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}
}

::v-deep.listDate .el-table__body-wrapper {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #262963;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #101341;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #101341;
  }
}
::v-deep.bag-tab .el-table__body-wrapper {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #101341;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #262963;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #262963;
  }
}

.coupon-bag-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .con {
    min-width: 780px;
  }

  .goods-wrapper {
    display: flex;
    align-items: center;
    .goods-cover {
      width: 50px;
      height: 50px;
      display: flex;
      background: #f6f6f6;
      border-radius: 8px;
      img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
    .goods-name {
      width: 1%;
      flex: 1;
      margin-left: 10px;
      font-size: 14px;
    }
  }
  .genera-breadcrumb {
    ::v-deep .el-breadcrumb__separator {
      margin: 0 9px;
      font-weight: 700;
      color: #5c5c6f;
    }
    ::v-deep .el-breadcrumb__item {
      &:last-child .el-breadcrumb__inner {
        color: #ababbb;
      }
      .el-breadcrumb__inner {
        color: #5c5c6f;
      }
    }
  }
  .listDate {
    height: 400px;
  }
  .bag-input {
    color: #fff;
    float: left;
    width: 40%;
    min-width: 311px;

    .tips {
      color: red;
      margin-left: 6px;
    }
    .bagTitle {
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      margin-left: 6px;
    }
    .el-input {
      width: 90%;
      height: 40px;
      // margin-top: 10px;
      margin: 10px 0 0 7px;
      border-radius: 4px;
    }
    .word1 {
      margin-left: 8px;
    }
  }
  ::v-deep .bag-list {
    width: 60%;
    min-width: 468px;
    display: inline;
    float: left;
    margin-top: 18px;
    padding: 0 10px;
    box-sizing: border-box;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .commodity-list {
        color: #fff;
      }
      .button {
        background: #4b45ff;
        color: #fff;
        border-color: #4b45ff;
        line-height: 0.5;
      }
    }
    .bag-tab {
      background-color: #0c0e3f;
      padding: 20px;
    }
  }
  ::v-deep .el-dialog {
    background: #070932;
    color: #fff;
  }

  ::v-deep .form-button {
    position: fixed;
    bottom: 1.85%;
    left: 68%;
    width: 167px;
    height: 40px;
    .el-button {
      color: #fff;
      border: 1px solid #262963;
      background: #0c0e3f;
    }

    .el-button--primary {
      background: #4b45ff;
    }
    .el-button + .el-button {
      margin-left: 12px;
    }
  }
  ::v-deep .bag-list {
    .el-input {
      width: 38%;
    }
    .el-input__inner {
      color: #ffffff;
      background-color: #070932 !important;
      border: 1px solid transparent;
      &::placeholder {
        color: #ffffff;
        opacity: 0.2;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .el-input__inner:focus {
      border: 1px solid #4b45ff;
    }
  }

  ::v-deep .el-form {
    .el-form-item {
      margin-bottom: 0;
    }
    .el-input__inner {
      color: #ffffff;
      background-color: #0c0e3f !important;
      border: 1px solid transparent;
      &::placeholder {
        color: #ffffff;
        opacity: 0.2;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .el-input__inner:focus {
      border: 1px solid #4b45ff;
    }
    .el-form-item__error {
      margin-left: 7px;
    }
    .el-select {
      width: 90%;
      margin-top: 10px;
    }
    .el-icon-arrow-up:before {
      content: "\e790";
      color: #fff;
      opacity: 0.5;
      width: 12px;
      height: 7px;
    }
    .el-select-dropdown {
      height: auto;
      border: 1px solid transparent;
      background-color: #0c0e3f;
    }
    .el-scrollbar {
      height: auto;
    }
    .el-scrollbar__wrap {
      margin-bottom: 0 !important;
    }
    .el-select-dropdown__item {
      height: 36px;
      margin-top: 9px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
    .el-select-dropdown__item:hover {
      background-color: #262963;
    }
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #262963;
    }
    .el-select-dropdown__item.selected {
      background-color: #262963;
    }
  }
}
.exam-content {
  height: 100%;
  width: 100%;
  background: #070932;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;

    .el-scrollbar__view {
      height: 100%;
    }
  }
  }
::v-deep .pages-center{
  background: none;
  padding: 0px 0px !important;
  margin-top: 10px;
}
::v-deep .el-pagination button:disabled{
      background: none;
          color: #909399
}
::v-deep .el-pagination .btn-next{
   background: none;
      color: #fff
}
::v-deep .el-pagination .btn-prev{
    background: none;
          color: #fff
}
::v-deep .el-pager li{
    background: none;
    color: #fff !important;
}
